/* global require */

'use strict';

// dependencies
var DOM = require('./dom');

/**
 * Provides a generous class toggling functionality that can be controlled
 * with data attributes.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @return {Object}
 */
var ClassToggles = function() {

    // shortcuts
    var self = this;

    // public variables
    self.version = '1.1.0';

    // private variables
    var currentlyOpenList = [];

    // private methods
    var addToCurrentlyOpenList;
    var closeCurrentlyOpen;
    var getTargets;
    var getTargetSelector;
    var onClick;

    /**
     * Adds the given target to the list of currently open elements including
     * it's trigger and toggleClass for later accessing purpose.
     *
     * @param {HTMLElement} target The target which is open right now.
     * @param {HTMLElement} trigger The trigger element of the target.
     * @param {String} toggleClass The class which is toggled.
     */
    addToCurrentlyOpenList = function(target, trigger, toggleClass) {
        var el;

        // check if auto close element is already in stack
        currentlyOpenList.forEach(function(item) {
            if (item.target === target) {
                return (el = item);
            }
        });

        // auto close element not found
        if (el === undefined) {
            return currentlyOpenList.push({
                target: target,
                triggers: [ trigger ],
                toggleClass: toggleClass
            });
        }

        // found auto close element, add trigger if not already in it
        if (el.triggers.indexOf(trigger) < 0) {
            el.triggers.push(trigger);
        }
    };

    /**
     * Closes all currently open targets. If a list of targets is provided, the
     * function will return a list of all closed targets which match the
     * provided list.
     *
     * @param {Array} targets Targets to check for.
     * @return {Array} List of elements which match the provided targets list.
     */
    closeCurrentlyOpen = function(targets) {
        var matchingTargets = [];


        // make sure targets is an array
        if (Array.isArray(targets) === false) {
            targets = [ targets ];
        }

        currentlyOpenList.forEach(function(item) {
            if (targets.indexOf(item.target) >= 0) {
                matchingTargets.push(item.target);
            }

            // remove toggle class
            item.target.classList.remove(item.toggleClass);
        });

        // clear list
        currentlyOpenList = [];

        return matchingTargets;
    };

    /**
     * Gets all targets of a given trigger element.
     *
     * @param {HTMLElement} trigger The trigger DOM element.
     * @return {Array} List of target elements.
     */
    getTargets = function(trigger) {
        var match;

        // Prefer toggle-for data attribute over href
        var targetSelector = getTargetSelector(trigger);

        // Target is parent element
        if (targetSelector === 'parent') {
            return [ trigger.parentElement ];
        }

        // Target should be one of the ancestors
        if (typeof targetSelector === 'string' && (match = targetSelector.match(/closest:(.*)/))) {
            var closest = DOM.closest(trigger, match[1]);

            return closest ? [ closest ] : [];
        }

        // Use target provided by attributes
        if (typeof targetSelector === 'string') {
            var targets = [];
            var list = targetSelector.split(',');

            for (var i = 0; i < list.length; i++) {
                var selector = list[i].trim();

                targets = targets.concat(Array.prototype.slice.call(DOM.selectAll(selector) || []));
            }

            return targets;
        }

        // Target element is trigger itself
        return [ trigger ];
    };

    /**
     * Gets the target selector of the given trigger element.
     *
     * @param {HTMLElement} trigger The trigger DOM element.
     * @return {String} The CSS selector.
     */
    getTargetSelector = function(trigger) {
        return DOM.data(trigger, 'toggle-for');
    };

    /**
     * The click handler which does the actual logic. It checks if a toggle
     * class element has been clicked, if auto closing should be activated for
     * this element and other features.
     *
     * @param {Event} e The originally emitted event.
     */
    onClick = function(e) {
        var trigger = DOM.closest(e.target, '[data-toggle-class]') || e.target;
        var toggleClass = DOM.data(trigger, 'toggle-class');
        var autoClose = DOM.data(trigger, 'toggle-autoclose');
        var preventDefault = DOM.data(trigger, 'toggle-prevent-default') === 'true';
        var recentlyClosed;

        // Select targets for this class toggle
        var targets = getTargets(trigger);

        // Close all currently open elements
        recentlyClosed = closeCurrentlyOpen(autoClose ? targets : []);

        // Not a toggle class element
        if (toggleClass === false) {
            return;
        }

        if (preventDefault) {
            e.preventDefault();
        }

        // Toggle class for all targets
        targets.forEach(function(target) {

            // Target was just recently closed, ignore it
            if (recentlyClosed.indexOf(target) >= 0) {
                return;
            }

            // Add element to watch list for automatic closing
            if (autoClose) {
                addToCurrentlyOpenList(target, trigger, toggleClass);
            }

            target.classList.toggle(toggleClass);
        });
    };

    /**
     * Public initialization method.
     */
    self.init = function() {

        // Bind click handler to all toggle class elements
        DOM.on('[data-toggle-class]', 'click', onClick);
    };
};

module.exports = ClassToggles;
