'use strict';
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Header = function Header() {
    this.init = function init() {

        $('#closeAllMenu').click(function (e) {
            $('.main-nav__menu__cantons').toggleClass('open2');
            $('.main-nav__list-container').toggleClass('open1');
        });

        $(document).on('click', '[search_result_item]', function (e) {
            e.preventDefault();

            var link = $(this).attr('href');
            var linkParts = link.split('goto/');

            if (linkParts.length === 2) {
                console.log(Object);

                window.open(atob(linkParts[1]), '_blank');
            }
        });

        return this;
    };
    return this;
};

module.exports = Header;