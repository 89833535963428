/* global require */

'use strict';

var DOM = require('../lib/dom.js');

var Tabs = function Tabs() {

    this.init = function init() {
        var className = 'active';
        var tabs = DOM.selectAll('[data-tabs]');
        var tabContents = DOM.selectAll('[data-tab-content]');
        var fn = function(e) {
            var tabs = DOM.selectAll('[data-tab]', e.currentTarget);
            var tab = DOM.closest(e.target, '[data-tab]');

            if (tab === null || tab.classList.contains(className)) {
                return;
            }

            for (var i = 0; i < tabContents.length; i++) {
                tabContents[i].classList.remove(className);
            }

            for (i = 0; i < tabs.length; i++) {
                if (tab !== tabs[i]) {
                    tabs[i].classList.remove(className);
                    continue;
                }

                tabs[i].classList.add(className);

                if (tabContents[i] !== undefined) {
                    tabContents[i].classList.add(className);
                }
            }
        };

        if (tabs) {
            for (var i = 0; i < tabs.length; i++) {
                DOM.on(tabs[i], 'click touchstart', fn);
            }
        }
    };

    return this;
};

module.exports = Tabs;

