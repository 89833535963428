/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Forms = require('../lib/forms');
var FilterToggle = require('./filterToggle.js');
var Events = require('./events.js');

window.jQuery = $;

require('bootstrap');
require("./../../../bower_components/jquery-nice-select/js/jquery.nice-select.min.js");

var Search = function Search(apiBaseUrl, language) {

    // Vars
    var $searchForm = $('#search-form');
    var $filterForm = $('#filter-form');
    var $mainForm = $searchForm;

    if ($filterForm.length > 0) {
        $mainForm = $filterForm;
    }

    var $lastSubmitOrigin;

    var $autocompleteField = $('[data-search-autocomplete]');

    var filterToggle = new FilterToggle();

    var attachHiddenSearchInfoToForm = function($form, type, typeLabel, urlNames, name, id) {
        $form.find('input[name="locationType"]').val(type);
        $form.find('input[name="locationTypeLabel"]').val(typeLabel);
        $form.find('input[name="locationUrlNames"]').val(urlNames);
        $form.find('input[name="locationName"]').val(name);
        $form.find('input[name="locationId"]').val(id);

        // If user has entered a zip manually, reset postal code search from before
        if (type === 'zip') {
            $form.find('input[name="postal_code"]').val('');
        }
    };

    var resetCoordsFields = function resetCoordsFields() {
        $('input[name="coords"]').val('');
        $('input[name="coords-default"]').val('');
        $('select[name="coords-distance"]').val('');
    };

    var submitForm = function submitForm($form) {
        filterToggle.saveState();
        $form.submit();
    };

    // Auto submit magic on form fields
    var initAutoSubmitters = function initAutoSubmitters() {
        var $autoSubmitters = $('[data-submit-on-change]');

        $autoSubmitters.change(function() {
            var $origin = $(this);
            if ($origin.data('submit-on-change') === true) {
                $lastSubmitOrigin = $origin;
                submitForm($(this).parents('form'));
            }
        });
    };

    var initSearchAndFilterForm = function initSearchAndFilterForm() {

        // When changing the category and a subcategory is selected, reset the subcategory
        $('select[name="category"]').change(function() {
            $('select[name="subcategory"]').val('');
        });

        // Handle submitting state for forms
        $filterForm.on('submit', function(e) {
            Forms.submit(this);
        });

        var formIsAllowedToSubmit = function formIsAllowedToSubmit(inputVal, coordsInputVal, zipInputVal) {

            // Submit form when rent/buy or category was selected
            if ($lastSubmitOrigin &&
                ($lastSubmitOrigin.data('super-category') ||
                $lastSubmitOrigin.is('#category'))) {
                    return true;
            }

            var inputGiven = !!(inputVal && inputVal.length && inputVal.length > 0);
            var coordsGiven = !!(coordsInputVal && coordsInputVal.length && coordsInputVal.length > 0);
            var zipGiven = !!(zipInputVal && zipInputVal.length && zipInputVal.length > 0);

            return inputGiven || coordsGiven || zipGiven;
        };

        // Submit magic
        $searchForm.submit(function(e) {
            var $input = $(this).find('input[name="search_query"]');
            var inputVal = $input.val();
            var $coordsInput = $(this).find('input[name="coords"]');
            var coordsInputVal = $coordsInput.val();
            var $zipInput = $(this).find('input[name="postal_code"]');
            var zipInputVal = $zipInput.val();

            // Do nothing when user entered nothing and no coords (locate me) were given
            if (!formIsAllowedToSubmit(inputVal, coordsInputVal, zipInputVal)) {
                e.preventDefault();
                return false;
            }

            // If there is a direct input, reset coords (and coords checkbox)
            if (inputVal && inputVal.length && inputVal.length > 0) {
                resetCoordsFields();
            }

            // Add submitting state (only if we open form in same window)
            if (!$searchForm.prop('target') || $searchForm.prop('target') === '_self') {
                Forms.submit($searchForm[0]);
            }

            // If we are on the search page we also have to attach the search query to the filter form
            if ($mainForm === $filterForm) {
                $mainForm.find('input[name="search_query"]').val(inputVal);
            }

            // If a user directly clicked on a suggestion, we already have all the information we need.
            var suggestionSelected = $mainForm.find('input[name="suggestion-selected"]').val();
            if (suggestionSelected === 'true') {

                // If we are on the search page, submit the filter form instead of the search form
                if ($mainForm === $filterForm) {
                    $lastSubmitOrigin = $input;
                    submitForm($mainForm);
                    e.preventDefault();
                }

                return true;
            }

            var autocomplete = $autocompleteField.data('autocomplete');
            var inputValLowerCase = inputVal.toLowerCase();
            var result = inputVal;
            var fullTextSearch = true;

            /*
             1. Take the suggestion which is written the same as the given string, if suggestions are available
             2. Take the first suggestions from the list, if suggestions are available
             3. Consider the given string as a full-text search-query
             */
            if (autocomplete) {
                var suggestions = autocomplete.suggestions;
                var i;

                for (i = 0; i < suggestions.length; i++) {
                    var suggestedName = suggestions[i].raw.name.toLowerCase();
                    if (i === 0) {
                        result = suggestions[i];
                        fullTextSearch = false;

                        if (inputValLowerCase === suggestedName) {
                            break;
                        }
                    }
                    else if (inputValLowerCase === suggestedName) {
                        result = suggestions[i];
                        fullTextSearch = false;
                        break;
                    }
                }
            }

            // If we take some suggestion from the autocomplete form, fill the main form with the result
            // Else we are interpreting the entered value as full-text search
            if (!fullTextSearch) {
                attachHiddenSearchInfoToForm($mainForm,
                    result.raw.type,
                    result.raw.typeLabel,
                    JSON.stringify(result.raw.urlNames),
                    result.raw.name,
                    result.raw.id);
            }
            // Fulltext or zip search?
            else {
                var fourDigits = /^\d{4}$/;
                if (fourDigits.test(result)) {
                    $mainForm.find('input[name="postal_code"]').val(result);
                } else {
                    $mainForm.find('input[name="fulltext"]').val(result);
                }
            }

            // If we are on the search page, submit the filter form instead of the search form
            if ($mainForm === $filterForm) {
                e.preventDefault();
                $lastSubmitOrigin = $searchForm;
                submitForm($mainForm);
            }
            else {
                filterToggle.saveState();
                $lastSubmitOrigin = null;
            }
        });
    };

    var onAutoCompleteSuggestionSelected = function onAutoCompleteSuggestionSelected(event, suggestion) {
        var urlNames = [];
        $.each(suggestion.raw.geoLocation.urlNames, function(i, el) {
            var t = {};
            t.language = i;
            t.name = el.split('-').slice(1).join('-');
            urlNames.push(t);
        });
        attachHiddenSearchInfoToForm($mainForm,
            suggestion.type,
            suggestion.typeLabel,
            JSON.stringify(urlNames),
            suggestion.name,
            suggestion.id);

        $mainForm.find('input[name="suggestion-selected"]').val(true);

        // Reset coords (and coords checkbox) when selecting a new location and hide coords distance field
        resetCoordsFields();

        $lastSubmitOrigin = $autocompleteField;
        submitForm($searchForm);
    };

    var onCategorySwitched = function onCategorySwitched(event, category) {
        var action = $searchForm.attr('action');
        action = action.substring(0, action.lastIndexOf('/') + 1);
        action += category;
        $searchForm.attr('action', action);
    };

    this.init = function init() {

        // Only activate script if we are search-result page
        if ($mainForm.length < 1) {
            return false;
        }

        // Init methods
        initAutoSubmitters();
        initSearchAndFilterForm();

        // Listeners
        $(document).on(Events.autoCompleteSuggestionSelected, onAutoCompleteSuggestionSelected);
        $(document).on(Events.superCategorySwitched, onCategorySwitched);

        return this;
    };

    return this;
};

module.exports = Search;
