/* global require */

'use strict';

// dependencies
var DOM = require('./dom');

/**
 * Bookmarking library.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @param {Object} options Options to change behaviour of bookmarking library.
 * @return {Bookmarks}
 */
var Bookmarks = function(options) {

    // shortcuts
    var self = this;

    // public variables
    self.version = '0.1.0';

    // private variables
    var bookmarks = {};
    var container;
    var defaults = {
        container: '',
        localStorageKey: 'bookmarks',
        onAddCallback: '',
        onRemoveCallback: '',
        template: ''
    };
    var settings = {};
    var template;

    /**
     * Adds a bookmark to the bookmark list.
     *
     * @param {String} id Bookmark id.
     * @param {Object} data Bookmark data.
     * @return {Bookmarks}
     */
    self.add = function(id, data) {

        // add new record to instance and storage
        if (bookmarks[id] === undefined) {

            // add data to bookmarks
            bookmarks[id] = data;

            // update local storage
            self.exportToLocalStorage();
        }

        // run callback
        if (typeof settings.onAddCallback === 'function') {
            settings.onAddCallback.call(this, id, data);
        }

        return self;
    };

    /**
     * Removes a bookmark from the bookmark list.
     *
     * @param {String} id Bookmark id.
     * @return {Bookmarks}
     */
    self.remove = function(id) {

        // delete record from instance and storage
        if (bookmarks[id] !== undefined) {

            // delete record
            bookmarks[id] = undefined;

            // update local storage
            self.exportToLocalStorage();
        }

        // run callback
        if (typeof settings.onRemoveCallback === 'function') {
            settings.onRemoveCallback.call(this, id);
        }

        return self;
    };

    /**
     * Adds a bookmark to the bookmark list if it's not already in it. If so,
     * it will be removed instead.
     *
     * TODO: @basti: JSDoc
     *
     * @param {String} id Bookmark id.
     * @return {Bookmarks}
     */
    self.swap = function(id, data) {
        if (bookmarks[id] === undefined) {
            return self.add(id, data);
        } else {
            return self.remove(id);
        }
    };

    /**
     * Gets a specific bookmark from the bookmark list.
     *
     * @param {String} id Bookmark id.
     * @return {Object} Bookmark data.
     */
    self.get = function(id) {
        if (bookmarks[id] === undefined) {
            return null;
        }

        return bookmarks[id];
    };

    /**
     * Returns all currently stored bookmarks.
     *
     * @return {Object}
     */
    self.list = function() {
        return bookmarks;
    };

    /**
     * Returns the amount of bookmarks that have been stored.
     *
     * @return {Number}
     */
    self.size = function() {
        var size = 0;

        for (var key in bookmarks) {
            if (bookmarks[key] === undefined) {
                continue;
            }

            size++;
        }

        return size;
    };

    /**
     * Synchronizes saved bookmarks with their DOM representations.
     *
     * @return {Bookmarks}
     */
    self.refresh = function() {
        var oldBookmarks = bookmarks;

        // import new bookmarks
        self.importFromLocalStorage();

        // remove obsolete bookmarks
        for (var key in oldBookmarks) {
            if (bookmarks[key] === undefined) {
                self.remove(key);
            }
        }

        // sync freshly added bookmarks
        for (key in bookmarks) {
            if (oldBookmarks[key] === undefined) {
                self.add(key, bookmarks[key]);
            }
        }

        return self;
    };

    /**
     * Exports bookmarks to localStorage.
     *
     * @return {Bookmarks}
     */
    self.exportToLocalStorage = function() {
        window.localStorage.setItem(settings.localStorageKey, JSON.stringify(bookmarks));

        return self;
    };

    /**
     * Imports previously saved bookmarks from localStorage.
     *
     * @return {Bookmarks}
     */
    self.importFromLocalStorage = function() {
        bookmarks = JSON.parse(window.localStorage.getItem(settings.localStorageKey)) || {};

        return self;
    };

    /**
     * Returns the markup template of a bookmark.
     *
     * @return {String}
     */
    self.getTemplate = function() {
        return template;
    };

    /**
     * Returns the Element that is holding all bookmarks.
     *
     * @return {Element}
     */
    self.getContainer = function() {
        return container;
    };

    /**
     * Initialization logic of Bookmarks library.
     */
    (function() {

        options =  options || {};

        // merge new settings into current ones
        for (var key in defaults) {
            if (options.hasOwnProperty(key)) {
                settings[key] = options[key];
            } else {
                settings[key] = defaults[key];
            }
        }

        // get container
        container = settings.container;

        // get template
        template = settings.template;

        // refresh
        self.refresh();

        // watch for storage changes
        DOM.on(window, 'storage', self.refresh);

    })();
};

module.exports = Bookmarks;
