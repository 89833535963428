/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Events = require('./events.js');

window.jQuery = $;

require('bootstrap');
require("./../../../bower_components/jquery-nice-select/js/jquery.nice-select.min.js");


var SearchWidget = function SearchWidget(language, baseUrl) {

    language = language || 'de';
    baseUrl = baseUrl || 'alle-immobilien.ch';

    var $searchWidgetForm;
    var apiBaseRoute;
    var apiRouteCurrent;
    var $submitButton;
    var selectUpdater;

    // Basic skeleton method to call the search widget API
    var callApi = function callApi(data, success, fail) {

        $(document).trigger(Events.beforeApiRequestSearchWidget);

        $.ajax({
            url: apiRouteCurrent,
            method: 'POST',
            dataType: 'json',
            data: data
        }).done(function (response) {
            if (success && typeof success === 'function') {
                success(response);
            }

            if (typeof response.data.resultCount !== 'undefined') {
                $(document).trigger(Events.resultCountUpdated, response.data.resultCount);
            }

            if (typeof response.data.searchParams !== 'undefined') {
                $(document).trigger(Events.autoCompleteNewParams, response.data.searchParams);
            }

            $(document).trigger(Events.afterApiRequestSearchWidget, response);
        }).fail(fail);
    };

    var getFormData = function getFormData() {
        var $fields = $searchWidgetForm.find(':input:not(button):not([name="searchCategory"])');
        var data = {};

        $fields.each(function () {
            var $field = $(this);
            data[$field.attr('name')] = $field.val();
        });

        return data;
    };

    var changeFormActionToIframeIntegration = function changeFormActionToIframeIntegration($form) {
        var integrate = $form.data('integrate');
        if (integrate) {
            $form.prop('target', '_blank');
            $form.find('input[name="integrate-blank"]').val(true);
        }
        else {
            $form.prop('target', '_parent');
        }
    };

    var attachHiddenSearchInfoToForm = function ($form, type, typeLabel, urlNames, name, id) {
        $form.find('input[name="locationType"]').val(type);
        $form.find('input[name="locationTypeLabel"]').val(typeLabel);
        $form.find('input[name="locationUrlNames"]').val(urlNames);
        $form.find('input[name="locationName"]').val(name);
        $form.find('input[name="locationId"]').val(id);

        // If user has entered a zip manually, reset postal code search from before
        if (type === 'zip') {
            $form.find('input[name="postal_code"]').val('');
        }
    };

    var resetCoordsFields = function resetCoordsFields() {
        $('input[name="coords"]').val('');
        $('input[name="coords-default"]').val('');
        $('select[name="coords-distance"]').val('');
    };

    var onSelectFieldChange = function onSelectFieldChange() {
        var $field = $(this);
        var fieldName = $field.prop('name');

        $(document).trigger(Events.selectFieldChangeSearchWidget, {field: $field, fieldName: fieldName});

        var data = getFormData();
        data.changed_attribute = fieldName; // jshint ignore:line
        //callApi(data);
    };

    // Updates select field with new values
    var updateSelectField = function updateSelectField($selectField, newData) {

        // Remove old options, add new ones
        $selectField.empty();

        $.each(newData, function () { // jshint ignore:line
            $selectField.append($('<option></option>')
                .attr('value', this.value).text(this.label));
        });

        $selectField.closest('.search-form__item__container').html($('<div />').append($selectField.clone()).html());
        $('#' + $selectField.attr('id')).niceSelect().after('<div class="vertical-divider hidden-xs"></div>');
    };

    var updateCategoryField = function updateCategoryField(category) {
        updateSelectField($('#category'), category.data);
    };

    var updatePriceFields = function updatePriceFields(prices) {
        updateSelectField($('#price_from'), prices.price_from); // jshint ignore:line
        updateSelectField($('#price_to'), prices.price_to); // jshint ignore:line
    };

    var onApiRequestStart = function onApiRequestStart() {
        $submitButton.parent().addClass('loading');
    };

    var onApiRequestDone = function onApiRequestDone(event, response) {
        if (response.data.resultCount) {
            delete response.data.resultCount;
        }
        if (response.data.searchParams) {
            delete response.data.searchParams;
        }

        $.each(response.data, function (key, value) {
            // Special cases. Generic version is too complex and unnecessary at the moment
            if (key === 'category') {
                updateCategoryField(value);
            }
            else if (key === 'price') {
                updatePriceFields(value);
            }
        });

        $submitButton.parent().removeClass('loading');
    };

    var onLocateMeStart = function onLocateMeStart() {

        $submitButton.parent().addClass('loading');

        // Reset everything we are going to fill after successful locate-me -- fresh start.
        resetCoordsFields();
        $('input[name="search_query"]').val('');
        $searchWidgetForm.find('input[name="postal_code"]').val('');
        var $areaContainer = $searchWidgetForm.find('[data-filter-area]');
        $areaContainer.find('.filters__group__filter__select').addClass('disabled');
        $areaContainer.find('#coords-distance')[0].selectedIndex = 0;
        $areaContainer.find('#coords-distance').prop('disabled', 'disabled');
    };

    var onLocateMeEnd = function onLocateMeEnd() {
        $submitButton.parent().removeClass('loading');
    };

    // 1. Get coordinates from browser
    // 2. Get postal code from backend by submitting the coordinates
    var onLocateMeSucces = function onLocateMeSucces(event, coordsString) {
        var $searchField = $searchWidgetForm.find('input[name="search_query"]');
        var data = getFormData();
        data.coords = coordsString;
        data.changed_attribute = 'coords'; // jshint ignore:line

        $searchWidgetForm.find('input[name="coords"]').val('');

        // On success:
        // 1. Update the hidden postal_code form field
        // 2. Update the actual search field
        // 3. Activate the distance field
        var onGeoCodeSuccess = function onGeoCodeSuccess(response) {
            if (response.data.postalCodes && response.data.postalCodes.data) {
                var postalCode = response.data.postalCodes.data;
                $searchField.val(postalCode);
                var $postalCodesField = $searchWidgetForm.find('input[name="postal_code"]');
                if ($postalCodesField.length > 0) {
                    $postalCodesField.val(postalCode);
                }
                var $areaContainer = $searchWidgetForm.find('[data-filter-area]');
                $areaContainer.find('.filters__group__filter__select').removeClass('disabled');
                $areaContainer.find('#coords-distance').prop('disabled', false);
            }
        };

        callApi(data, onGeoCodeSuccess);
    };

    var onAutoCompleteSuggestionSelected = function onAutoCompleteSuggestionSelected(event, suggestion) {
        var urlNames = [];
        $.each(suggestion.raw.geoLocation.urlNames, function(i, el) {
            var t = {};
            t.language = i;
            t.name = el.split('-').slice(1).join('-');
            urlNames.push(t);
        });
        attachHiddenSearchInfoToForm($searchWidgetForm,
            suggestion.type,
            suggestion.typeLabel,
            JSON.stringify(urlNames),
            suggestion.name,
            suggestion.id
        );

        $searchWidgetForm.find('input[name="suggestion-selected"]').val(true);

        resetCoordsFields();

        var data = getFormData();
        data.changed_attribute = 'search_query'; // jshint ignore:line
        //callApi(data);
    };

    var onResultCountUpdate = function onResultCountUpdate(event, resultCount) {
        var $resultCount = $submitButton.find('.result-count');

        // Update result count on button
        if ($resultCount.length > 0) {
            $resultCount.html(resultCount);
        }

        // Change button label
        else {
            var newButtonContent = $submitButton.data('label-with-results');
            $submitButton.html(newButtonContent).find('.result-count').html(resultCount);
        }
    };

    var onSuperCategorySwitched = function onSuperCategorySwitched(event, category) {

        // Change AJAX api route
        apiRouteCurrent = apiBaseRoute + '/' + category;

        // Change form action
        var action = $searchWidgetForm.attr('action');
        action = action.substring(0, action.lastIndexOf('/') + 1);
        action += category;
        $searchWidgetForm.attr('action', action);

        var data = getFormData();
        data.changed_attribute = 'supercategory'; // jshint ignore:line
        //callApi(data);
    };

    var getCurrentApiRoute = function setInitialApiRoute() {
        var route = apiBaseRoute + '/rent';
        var $buyRadio = $searchWidgetForm.find('input#searchCategoryBuy');

        if ($buyRadio.length > 0 && $buyRadio.is(':checked')) {
            route = apiBaseRoute + '/buy';
        }

        return route;
    };

    var initSearchFormTabs = function initSearchFormTabs($form) {
        $form.find('.js-search-form__type').click(function (e) {
            var tab = $(this);
            var type = tab.data('type');
            var types = ['#searchCategoryRent', '#searchCategoryBuy'];
            var translations = {
                '#searchCategoryRent': {
                    de: 'mieten',
                    fr: 'louer',
                    it: 'affitta',
                    en: 'rent'
                },
                '#searchCategoryBuy': {
                    de: 'kaufen',
                    fr: 'acheter',
                    it: 'compra',
                    en: 'buy'
                }
            };

            if ($.inArray(type, types) > -1) {
                $('.js-search-form__tabs').find('radio').prop('checked', false);
                $(type).prop('checked', true).trigger('change');
            } else {
                console.log('Unrecognized search type "' + type + '".');
            }

            // Changing buy/rent in links
            $('.one_line_ad a').each(function () {
                var $self = $(this);
                var parts = $self.attr('href').split('/');

                for(var i = 0; i < parts.length; i++) {
                    if(i === 2) {
                        parts[i] = translations[type][language];
                    }
                }

                $self.attr('href', parts.join('/'));
            });
        });
    };

    /**
     * Handle all actions that need to bee done when browser window is resized
     */
    var onWindowResize = function onWindowResize(event) {

        // Set width and height for topOffer iframe
        var cantonsBox = document.getElementById('cantonsBox');
        var width = cantonsBox.offsetWidth;
        var height = cantonsBox.offsetHeight;

        if ($(window).width() < 768) {
            width = '100%';
            height = 400;
        }

        $('#topOffer')
            .attr('width', width)
            .attr('height', height);
    };

    this.init = function init() {

        // No need to do anything if we are not on search-widget page
        $searchWidgetForm = $('#search-form-widget');

        if (!$searchWidgetForm || $searchWidgetForm.length < 1) {
            return false;
        }

        // Set vars
        $submitButton = $searchWidgetForm.find('.widget--search__form__submit');
        apiBaseRoute = window.location.protocol + '//' + baseUrl + '/' + language + '/search-ajax';
        apiRouteCurrent = getCurrentApiRoute();

        // Initialization
        initSearchFormTabs($searchWidgetForm);

        // Init nice select
        $('#category').niceSelect();
        $('#cantons').niceSelect();

        // Event Listeners
        $(document).on(Events.autoCompleteSuggestionSelected, onAutoCompleteSuggestionSelected);
        $(document).on(Events.locateMeStart, onLocateMeStart);
        $(document).on(Events.locateMeEnd, onLocateMeEnd);
        $(document).on(Events.locateMeSuccess, onLocateMeSucces);
        $(document).on(Events.resultCountUpdated, onResultCountUpdate);
        $(document).on(Events.superCategorySwitched, onSuperCategorySwitched);
        $(document).on(Events.beforeApiRequestSearchWidget, onApiRequestStart);
        $(document).on(Events.afterApiRequestSearchWidget, onApiRequestDone);

        $(window).trigger('resize');

        return this;
    };

    return this;
}
    ;

    module.exports = SearchWidget;
