/* global require */

'use strict';

var DOM = require('../lib/dom.js');
var Flickity = require('flickity');

var Wemf = function Wemf() {

    var _self = this;

    /**
     * Set up events to track
     */
    this.init = function() {

        // Generic data attribute to allow tracking
        DOM.on('[data-wemf-tracking]', 'click', this.track);

        // Gallery fullview mode button
        DOM.on('.detail__gallery__images__magnify', 'click', this.track);

        // On changing the current slide in the gallery
        var gallery = DOM.select('[data-gallery-images]');
        if (!gallery) { return this; }
        var flickity = Flickity.data(gallery);
        if (!flickity) { return this; }
        var selectedIndexPast = flickity.selectedIndex;
        var selectedIndexPresent;
        flickity.on('cellSelect', function() {
            selectedIndexPresent = flickity.selectedIndex;
            if (selectedIndexPresent !== selectedIndexPast) {
                _self.track();
                selectedIndexPast = flickity.selectedIndex;
            }
        });

        return this;
    };

    this.track = function track() {

        // Special case: Don't track when we are on search-widget or article-widget
        // See: MI-135 & MI-163
        if (DOM.select('body.search-widget') || DOM.select('body.search-results-widget')) {
            return this;
        }

        var wemfImg = document.getElementById('wemfImg');

        // Depends on device.js. Defaults to not mobile.
        var isMobile = /mobile|tablet/.test(document.documentElement.className);
/*
        if (wemfImg !== null) {
            if ('https:' === document.location.protocol) {
                var WEMF = 'https://home-ssl.wemfbox.ch/cgi-bin/ivw-ssl/CP/ssl/extern/20min' + (isMobile ? '/mobilesite' : '');
                wemfImg.src = WEMF + '?r='+encodeURI(document.referrer)+'&d=' + (Math.random() * 100000);
            } else {
                var IVW = 'http://homegate.wemfbox.ch/cgi-bin/ivw/CP/extern/20min' + (isMobile ? '/mobilesite' : '');
                wemfImg.src = IVW + '?r='+encodeURI(document.referrer)+'&d=' + (Math.random() * 100000);
            }
        }
*/
        return this;
    };

    return this;
};

module.exports = Wemf;
