/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Forms = require('../lib/forms');

var Contact = function Contact(language) {
    var contactFormOnSuccess;
    var contactFormOnError;

    language = language || 'de';

    var $contact = {
        form: $('[data-form-contact]'),
        firstname: $('input[name=firstname]'),
        lastname: $('input[name=lastname]'),
        email: $('input[name=email]'),
        phone: $('input[name=phone]'),
        message: $('textarea[name=message]'),
        remember: $('input[name=rememberDetails]')
    };

    var $submittedContactPanel = $('[data-form-contact-submitted]');
    var $newContactLink = $('[data-create-new-contact]');
    var contactUrl = $contact.form.attr('action');
    var lastDetails = JSON.parse(window.localStorage.getItem('lastContactFormDetails')) || {};

    this.init = function init() {

        // Reinject last details
        if (typeof lastDetails === 'object') {
            for (var key in lastDetails) {
                if ($contact.hasOwnProperty(key)) {
                    $contact[key].val(lastDetails[key]);
                }
            }
        }

        // If contact form shall be submitted, intefere
        $contact.form.on('submit', function(e) {

            // Set submitting state
            Forms.submit($contact.form[0]);

            // Do not load url of form action
            e.preventDefault();

            // Gather initial data set that will be stored in localStorage
            var data = {
                firstname: $contact.firstname.val(),
                lastname: $contact.lastname.val(),
                email: $contact.email.val(),
                phone: $contact.phone.val(),
                lang: language
            };

            // Save contact details in local storage
            // 'Remember my contact details' functionality
            if ($contact.remember.is(':checked')) {
                window.localStorage.setItem('lastContactFormDetails', JSON.stringify(data));
            }

            // Append message to data set
            data.message = $contact.message.val();

            $.ajax(contactUrl, { data: data })
                .always(function(data, textStatus, jqXHR) {

                    // The request is completed: remove submitting state from form
                    Forms.abort($contact.form[0]);

                    if (data.status && data.status === 'success') {
                        return contactFormOnSuccess();
                    }

                    contactFormOnError(data);
                });
        });

        // Bring back contact form
        $newContactLink.click(function(e) {
            e.preventDefault();
            $submittedContactPanel.slideUp();
            $contact.form.slideDown();
        });
    };

    contactFormOnSuccess = function() {

        // Google Tag Manager tracking
        if (window.dataLayer) {
            window.dataLayer.push({ 'event': 'detail-contact-submit' });
        }

        // Reset possible errors
        $contact.form.find('.has-error').removeClass('has-error');
        $contact.form.find('.error-text').remove();
        $contact.message.val(''); // Reset message field
        $contact.form.slideUp();
        $submittedContactPanel.slideDown();
    };

    contactFormOnError = function(response) {

        if (!response || !response.data) {
            return null;
        }

        // Remove old errors
        $contact.form.find('.error-text').remove();

        // Generic API errors
        if (response.data.api) {
            var message = '<div class="error-text margin-bottom">' + response.data.api + '</div>';
            $(message).prependTo($contact.form);
        }

        // Per field errors
        for (var field in $contact) {
            if ($contact.hasOwnProperty(field)) {
                var $formGroup = $contact[field].parents('.form-group');
                if (response.data.hasOwnProperty(field)) {
                    $formGroup.addClass('has-error');
                    var $helpText = $('<div class="error-text">' + response.data[field] + '</div>');
                    $helpText.appendTo($contact[field].parent());
                }
                else {
                    $formGroup.removeClass('has-error');
                }
            }
        }
    };

    return this;
};

module.exports = Contact;
