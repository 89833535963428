/* global require */

'use strict';

var DOM = require('../lib/dom.js');

/**
 * We need to dynamically set the min-height of the detail content elements,
 * because of the fucked up design requirements.
 */
var DetailContentHeight = function DetailContentHeight() {

    this.init = function init() {
        var gallery = DOM.select('[data-gallery]');
        var detailContents = DOM.selectAll('[data-tab-content]');

        var updateMinHeight = function(e) {
            var height = gallery.getBoundingClientRect().height + 'px';

            for (var i = 0; i < detailContents.length; i++) {
                detailContents[i].style.minHeight = height;
            }
        };

        // Only set min height if gallery is given
        if (gallery === null || detailContents === null) {
            return;
        }

        DOM.on(window, 'resize load', updateMinHeight);
    };

    return this;
};

module.exports = DetailContentHeight;

