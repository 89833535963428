/* global require */

'use strict';

var FilterToggle = function FilterToggle() {

    this.saveState = function saveState() {
        var searchWidget = document.querySelector('#searchWidget');

        try {
            window.localStorage.setItem('filtersOpen',
                document.body.classList.contains('offscreen--show') ||
                (searchWidget && searchWidget.classList.contains('open'))
            );
        }
        catch (error) {
            console.warn(error);
        }
    };

    return this;
};

module.exports = FilterToggle;
