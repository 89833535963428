/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Events = require('./events.js');

var EditorWidget = function EditorWidget() {

    var $editorWidget;
    var $filterForm;

    var locateMeEnd = function locateMeEnd() {
        $filterForm.submit();
    };

    this.init = function init() {

        $editorWidget = $('.editor-widget');
        $filterForm = $editorWidget.find('#filter-form');

        // Not on search result widget site, do nothing.
        if ($editorWidget.length < 1 || $filterForm.length < 1) {
            return this;
        }

        $(document).on(Events.locateMeEnd, locateMeEnd);
    };

    return this;
};

module.exports = EditorWidget;

