/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Events = require('./events.js');

var SearchCategorySwitcher = function SearchCategorySwitcher() {

    var initSuperCategorySwitch = function initSuperCategorySwitch() {

        // Switchers as radios (search-widget)
        $('input[name="searchCategory"]').change(function() {

            // Only allowing 'rent' or 'buy', else nothing happens
            if (this.value === 'rent' || this.value === 'buy') {
                $(document).trigger(Events.superCategorySwitched, this.value);
            }
        });
    };

    this.init = function init() {

        initSuperCategorySwitch();

        return this;
    };

    return this;
};

module.exports = SearchCategorySwitcher;
