/* global require */

'use strict';

var DOM = require('../lib/dom.js');
var FilterToggle = require('./filterToggle.js');

var ClearLinks = function ClearLinks() {

    this.init = function init() {

        var filterToggle = new FilterToggle();

        DOM.on('.filters__group__filter__clear', 'click', function() {
            filterToggle.saveState();
        });
    };

    return this;
};

module.exports = ClearLinks;
