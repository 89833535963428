/* global require */

'use strict';

var DOM = require('../lib/dom.js');

/**
 * Within the search widget the select fields shall automatically update
 * the fake value on change. Due to the fact that the page is not being
 * reloaded and the fake value field is a separate DOM element we need to
 * handle it via JS.
 */
var FakeSelectValueUpdater = function FakeSelectValueUpdater() {

    var self = this;

    this.onChange = function(e) {
        self.change(e.currentTarget);
    };

    this.change = function(target) {
        target.nextElementSibling.textContent = target.options[target.selectedIndex].textContent;
    };

    this.init = function init() {
        var selects = DOM.selectAll('.select');

        if (!selects || !selects.length) {
            return this;
        }

        for (var i = 0; i < selects.length; i++) {
            var select = selects[i];

            DOM.on(select, 'change', self.onChange);
        }
    };

    return this;
};

module.exports = FakeSelectValueUpdater;

