/* global define */

'use strict';

module.exports = (function Events() {
    /**
     * Namespaced custom event definitions.
     * Modules should not use the event strings directly
     * but rather include this module and access its properties
     * to trigger or bind application events.
     */
    return {
        locateMeStart: 'locateMe.start.20min',
        locateMeSuccess: 'locateMe.success.20min',
        locateMeEnd: 'locateMe.end.20min',
        superCategorySwitched: 'superCategorySwitched.20min',
        autoCompleteNewParams: 'newParams.autoComplete.20min',
        autoCompleteSuggestionSelected: 'suggestionSelected.autoComplete.20min',
        beforeApiRequestSearchWidget: 'beforeApiRequest.searchWidget.20min',
        afterApiRequestSearchWidget: 'afterApiRequest.searchWidget.20min',
        resultCountUpdated: 'updated.resultCount.20min',
        selectFieldChangeSearchWidget: 'selectFieldChanged.searchWidget.20min'
    };
})();
