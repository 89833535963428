/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Events = require('./events.js');

var LocateMe = function LocateMe() {

    // Private vars
    var $locateMeButtons = $('[data-locate-me]');
    var $coordsFormField;
    var $form;
    var $container;
    var defaultDistance;
    var geolocationOptions;
    var $errorModal;
    var loadingClass = 'locate-me-in-progress';

    /**
     * @param coords
     * @returns {string} [lat],[lon]
     */
    var coordsToString = function coordsToString(coords) {
        return coords.latitude + ',' + coords.longitude;
    };

    /**
     * @param coords
     * @param distance
     * @returns {string} [lat],[lon]-[distance (radius) in km]
     */
    var coordsToStringWithDistance = function coordsToStringWithDistance(coords, distance) {
        distance = distance || defaultDistance;
        return coordsToString(coords) + '-' + distance;
    };

    var writeCoordsToFormField = function writeCoordsToFormField(coordsString) {
        $coordsFormField.val(coordsString);
    };

    var onGeolocationSuccess = function onGeolocationSuccess(position) {

        // Get coords in format as expected in our API:
        var coordsString = coordsToStringWithDistance(position.coords);

        // Always write coords to form field
        writeCoordsToFormField(coordsString);

        $container.removeClass(loadingClass);

        $(document).trigger(Events.locateMeSuccess, coordsString);
        $(document).trigger(Events.locateMeEnd);
    };

    var onGeolocationError = function onGeolocationError(error) {

        if (error.message) {
            console.warn(error.message);
        }

        $container.removeClass(loadingClass);

        if ($errorModal && $errorModal.length > 0) {
            $errorModal.addClass('open');
        }

        $(document).trigger(Events.locateMeEnd);
    };

    var initLocateMe = function initLocateMe() {

        // Nothing to do here...
        if ($locateMeButtons.length < 1) {
            return false;
        }

        // Init vars
        $coordsFormField = $('input[name="coords"]');
        $form = $locateMeButtons.parents('form');
        $container = $form;
        if ($container.length < 1) {
            $container = $('body');
        }
        defaultDistance = 10;
        geolocationOptions = {
            enableHighAccuracy: true,
            timeout: 7000,
            maximumAge: 0
        };
        $errorModal = $('#geolocation-error');

        // Geolocation not available
        if (!(navigator && 'geolocation' in navigator)) {
            return false;
        }

        $locateMeButtons.click(function(e) {
            e.preventDefault();

            $(document).trigger(Events.locateMeStart, e);

            $container.addClass(loadingClass);

            navigator.geolocation.getCurrentPosition(onGeolocationSuccess, onGeolocationError, geolocationOptions);
        });

        return true;
    };

    this.init = function init() {
        initLocateMe();

        return this;
    };

    return this;
};

module.exports = LocateMe;
