'use strict';

/**
 * Controller for TATM library.
 *
 * @author Simo Jakovic <simo.jakovic@homegate.ch>
 * @return {Object}
 */
var AlleImmoTATM = function () {

    // shorcut
    var self = this;

    var screenW;
    var websiteContentWidth;
    var tatmData;
    var dfpTargetingObj;
    // Visited page: front, SRP
    var page;

    var helper = (function () {

        var getRentPriceRange = function (price) {
            if (price <= 0) {
                return '';
            } else if (price < 1500) {
                return '0-1499';
            } else if (price < 2000) {
                return '1500-1999';
            } else if (price < 2500) {
                return '2000-2499';
            } else if (price < 3000) {
                return '2500-2999';
            } else if (price < 3500) {
                return '3000-3499';
            } else if (price < 4000) {
                return '3500-3999';
            } else if (price < 4500) {
                return '4000-4499';
            } else if (price < 5000) {
                return '4500-4999';
            } else if (price < 10000) {
                return '5000-9999';
            } else if (price > 9999) {
                return 'more_9999';
            }
            return '';
        };

        var getBuyPriceRange = function (price) {
            if (price <= 0) {
                return '';
            } else if (price < 150000) {
                return '0-149999';
            } else if (price < 300000) {
                return '150000-299999';
            } else if (price < 500000) {
                return '300000-499999';
            } else if (price < 750000) {
                return '500000-749999';
            } else if (price < 1000000) {
                return '750000-999999';
            } else if (price < 1250000) {
                return '1000000-1249999';
            } else if (price < 1500000) {
                return '1250000-1499999';
            } else if (price < 2000000) {
                return '1500000-1999999';
            } else if (price < 3000000) {
                return '2000000-2999999';
            } else if (price < 6000000) {
                return '3000000-5999999';
            } else if (price < 30000000) {
                return '6000000-29999999';
            } else if (price > 29999999) {
                return 'more_29999999';
            }
            return '';
        };

        return {
            getRentPriceRange: getRentPriceRange,
            getBuyPriceRange: getBuyPriceRange

        };
    })();

    var execAdLoading = function () {

        var ads = [];

        switch (window.deviceType) {

            case 'mobile':
                if (page === 'front') {
                    ads.push({
                        adUnitName: 'inside-full-pos1',// Homepage Mobile
                        targetContainerId: 'homepage_mobile',
                        callback: false,
                        adHeader: ''
                    });
                }

                if (page === 'SRP') {
                    ads.push({
                        adUnitName: 'inside-full-pos1',// SRP Mobile
                        targetContainerId: 'srp_mobile_2',
                        callback: false,
                        adHeader: ''
                    });
                   /* ads.push({
                        adUnitName: 'inside-full-pos2',// SRP Mobile
                        targetContainerId: 'srp_mobile_10',
                        callback: false,
                        adHeader: ''
                    });
                    ads.push({
                        adUnitName: 'inside-full-pos3',// SRP Mobile
                        targetContainerId: 'srp_mobile_18',
                        callback: false,
                        adHeader: ''
                    }); */
                }
                break;

            case 'tablet':
                if (page === 'front') {
                    ads.push({
                        adUnitName: 'inside-full-top',// Homepage Landscape Tablet
                        targetContainerId: 'homepage_content',
                        callback: false,
                        adHeader: ''
                    });
                }

                if (page === 'SRP') {
                    ads.push({
                        adUnitName: 'inside-full-top',// Tablet Header Front, SRP
                        targetContainerId: 'header_content',
                        callback: false,
                        adHeader: ''
                    });
                    ads.push({
                        adUnitName: 'inside-full-pos1',// SRP Portrait Tablet
                        targetContainerId: 'srp_tablet_portrait_2',
                        callback: false,
                        adHeader: ''
                    });
                    ads.push({
                        adUnitName: 'inside-full-footer',// Tablet SRP before pagination
                        targetContainerId: 'srp_footer',
                        callback: false,
                        adHeader: ''
                    });
                    /*ads.push({
                        adUnitName: 'inside-full-pos2',// SRP Portrait Tablet
                        targetContainerId: 'srp_tablet_portrait_10',
                        callback: false,
                        adHeader: ''
                    });
                    ads.push({
                        adUnitName: 'inside-full-pos3',// SRP Portrait Tablet
                        targetContainerId: 'srp_tablet_portrait_18',
                        callback: false,
                        adHeader: ''
                    }); */
                }
                break;

            default:
                if (page === 'SRP') {
                    ads.push({
                        adUnitName: 'inside-full-top',// Desktop Header Front, SRP
                        targetContainerId: 'header_content',
                        callback: false,
                        adHeader: ''
                    });

                    ads.push({
                        adUnitName: 'outside-right',// Desktop SRP Sidebar
                        targetContainerId: 'sidebar_content',
                        callback: false,
                        adHeader: ''
                    });

                    ads.push({
                        adUnitName: 'inside-full-pos1',// SRP after 2
                        targetContainerId: 'srp_desk_2',
                        callback: false,
                        adHeader: ''
                    });

                    ads.push({
                        adUnitName: 'inside-full-pos2',// SRP after 11
                        targetContainerId: 'srp_desk_11',
                        callback: false,
                        adHeader: ''
                    });

                    ads.push({
                        adUnitName: 'inside-full-footer',// Desktop SRP before pagination
                        targetContainerId: 'srp_footer',
                        callback: false,
                        adHeader: ''
                    });


                }
                if (page === 'front') {
                    ads.push({
                        adUnitName: 'inside-full-top',// Desktop Header Front, SRP
                        targetContainerId: 'homepage_content',
                        callback: false,
                        adHeader: ''
                    });
                }

                break;
        }

        window.TATM.initAdUnits(ads);
    };

    var execAdDisplay = function () {
        window.TATM.displayAds();
    };

    var initializeAds = function () {

        if ('TATM' in window) {
            window.TATM.init(
                document.getElementById('dfpPagename').value,
                document.getElementById('dfpAdunit').value,
                dfpTargetingObj,
                false,
                websiteContentWidth
            );
            execAdLoading();
            execAdDisplay();
        }
    };

    self.init = function () {
        page = document.getElementById('dfpAdunit').value;
        screenW = window.innerWidth;
        websiteContentWidth = (screenW > 1024) ? 1024 : false;
        tatmData = JSON.parse(document.getElementById('tatm_data').value);
        dfpTargetingObj = {
            'tutti_kvorientation': window.deviceOrientation,
            'tutti_kvtype': tatmData.pageType,
            'tutti_kvregion': tatmData.canton,
            'tutti_kvoffertype': tatmData.offerType,
            'tutti_kvobject_type': tatmData.objectType,
            'tutti_kvrentprice_ran': (tatmData.category === 'rent') ? helper.getRentPriceRange(tatmData.priceTo || tatmData.priceFrom) : '',
            'tutti_buyprice_ran': (tatmData.category === 'buy') ? helper.getRentPriceRange(tatmData.priceTo || tatmData.priceFrom) : '',
            'tutti_kvroom': (tatmData.roomsFrom || tatmData.roomsFrom) || '',
        };

        // Register TATM global callback
        window.tatmIsLoaded = function () {
            initializeAds();
        };

        initializeAds();
    };


};
module.exports = AlleImmoTATM;