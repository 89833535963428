/* global require */

'use strict';

// dependencies
var Library = {
    DOM: require('../lib/dom'),
    GMaps: require('../lib/gmaps')
};

/**
 * Maps module.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @param {Object} vendor Vendor map provider.
 * @param {Object} options Options to change module behaviour.
 * @return {Maps}
 */
var Maps = function(vendor, options) {

    // shortcuts
    var self = this;
    var DOM = Library.DOM;

    // public variables
    self.version = '0.1.0';

    // private variables
    var container;
    var defaults = {
        container: '[data-map]',
        mapOptions: {
            zoom: 15,
            scrollwheel: false
        }
    };
    var icons = [];
    var map;
    var settings = {};
    var supports = [ 'google' ];
    var types = [];

    /**
     * Returns the container of the map module.
     *
     * @return {Element}
     */
    self.getContainer = function() {
        return container;
    };

    /**
     * Initialization logic of Maps module.
     */
    (function() {

        options =  options || {};

        // merge new settings into current ones
        for (var key in defaults) {
            if (options.hasOwnProperty(key)) {
                settings[key] = options[key];
            } else {
                settings[key] = defaults[key];
            }
        }

        // unsupported map provider
        if (supports.indexOf(vendor) < 0) {
            return console.warn('Maps module doesn\'t support maps from a vendor called \'' + vendor + '\'.');
        }

        // get container
        if (typeof settings.container === 'string') {
            container = DOM.select(settings.container);
        } else {
            container = settings.container;
        }

        // container not found
        if (container === null) {
            return console.warn('Maps container not found.');
        }

        // init google maps
        if (vendor.toLowerCase() === 'google' && 'google' in window) {

            // create instance of GMaps
            map = new Library.GMaps(window.google, {
                container: container,
                mapOptions: settings.mapOptions
            });
        }

        // use location provided by container
        map.setCenter(JSON.parse(DOM.data(container, 'location')));

        // add persistent marker for advertisement location
        map.addMarker({
            position: map.getCenter(),
            icon: DOM.data(container, 'icon'),
            persistent: true
        });

    })();
};

module.exports = Maps;
