/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Events = require('./events.js');
require('autocomplete');

var SearchAutoComplete = function SearchAutoComplete(apiBaseUrl, language) {
    // Defaults
    apiBaseUrl = apiBaseUrl || 'https://www.homegate.ch/rs';
    language = language || 'de';

    // Vars
    var apiUrl = '/api-search.php';
    var isValidSearchFormWidget = true;

    var $searchForm = $('#search-form');
    var $filterForm = $('#filter-form');
    var $searchFormWidget = $('#search-form-widget');
    var $searchWhere = $('#search-where');
    var $btnSubmitResultsPage = $('#search-results-search-submit');
    var $mainForm = $searchForm;
    if ($filterForm.length > 0) {
        $mainForm = $filterForm;
    }

    var isFrontPage = $searchForm === $mainForm && !$mainForm.hasClass('widget--search__form');
    var searchParams = {};

    if (isFrontPage) {

        $searchWhere.keyup(function (e) {
            if(e.which === 13) {
                return false;
            }
            isValidSearchFormWidget = false;
        });

        // Search form widget event on homepage
        $searchFormWidget.submit(function (e) {
            if (!isValidSearchFormWidget) {
                $searchWhere.val('');
                return false;
            }
        });
    } else {
        var $searchField = $('#search-field');

        $searchField.keyup(function (e) {
            if(e.which === 13) {
                return false;
            }
            isValidSearchFormWidget = false;
        });

        // Filter form widget event on results page
        $filterForm.submit(function (e) {
            if (!isValidSearchFormWidget) {
                $searchField.val('');
                $searchField.attr('placeholder', '');
                return false;
            }
        });
    }

    var $autocompleteField = $('[data-search-autocomplete]');

    var initAutocomplete = function initAutocomplete() {
        // Check if there are other parameters set than the default parameters defined above. If so, merge the parameters.
        // This is used so the current search parameters defined by the filter are also respected when searching for a new
        // location. The searchParameters are set in search-results.twig, and are not used on the front page.
        if (window.appConfig && window.appConfig.searchParameters) {
            searchParams = window.appConfig.searchParameters;
        }

        $autocompleteField.autocomplete({
            serviceUrl: apiUrl,
            paramName: 'name',
            params: searchParams,
            dataType: 'json',
            minChars: 3,
            preserveInput: false,
            maxHeight: 114,
            noCache: true,
            triggerSelectOnValidInput: false,
            transformResult: function (response) {
                return {
                    suggestions: $.map(response.results, function (item) {
                        return {
                            value: item.geoLocation.names[language][0],
                            data: item.geoLocation.urlNames[language],
                            name: item.geoLocation.names[language][0],
                            type: item.geoLocation.type,
                            typeLabel: item.geoLocation.typeLabel[language],
                            id: item.geoLocation.id,
                            raw: item
                        };
                    })
                };
            },
            formatResult: function (suggestion) {
                var html = '<span class="location">' + suggestion.name + '</span>&nbsp;';
                html += '<span class="locationtype">' + suggestion.typeLabel + '</span>';

                return html;
            },
            onSelect: function (suggestion) {
                $(document).trigger(Events.autoCompleteSuggestionSelected, suggestion);
                isValidSearchFormWidget = true;
                if(!isFrontPage) {
                    $btnSubmitResultsPage.attr('disabled', false);
                }
            }
        });
    };

    var onCategorySwitched = function onCategorySwitched(event, category) {

        // Switch autocomplete search category
        $autocompleteField.autocomplete('setOptions', {params: searchParams});
    };


    // Merge new search params coming from the outside with the current ones
    var onNewSearchParams = function onNewSearchParams(event, newParams) {
        // Add new params
        $.each(newParams, function (key, value) {
            searchParams[key] = value;
        });

        // Remove no longer valid params
        $.each(searchParams, function (key, value) {
            // TODO: 'rfi' is a special case (sadly), since it never gets returned from the backend
            if (typeof newParams[key] === 'undefined' && key !== 'rfi') {
                delete searchParams[key];
            }
        });
    };

    this.init = function init() {

        initAutocomplete();

        $(document).on(Events.superCategorySwitched, onCategorySwitched);
        $(document).on(Events.autoCompleteNewParams, onNewSearchParams);

        return this;
    };

    return this;
};

module.exports = SearchAutoComplete;
