/* global require */

'use strict';

var DOM = require('../lib/dom.js');
var Flickity = require('flickity');

/**
 * Initializes the galleries on detail pages.
 */
var Gallery = function Gallery() {

    this.init = function init() {
        var galleryImages = DOM.select('[data-gallery-images]');
        var galleryLightbox = DOM.select('[data-gallery-lightbox]');
        var syncFn = function(target) {
            if (target.selectedIndex !== this.selectedIndex) {
                target.select(this.selectedIndex);
            }
        };

        // No gallery or empty gallery
        if (galleryImages === null || galleryImages.children.length < 1) {
            return;
        }

        // Init flickity
        var gallery = new Flickity(galleryImages, {
            setGallerySize: false,
            prevNextButtons: false,
            pageDots: false,
            lazyLoad: 2
        });

        // create lightbox for gallery images
        if (galleryLightbox === null) {
            return;
        }

        // grab all lightbox images
        var galleryLightboxImages = DOM.selectAll('img', galleryLightbox);
        var galleryLightboxItems = DOM.selectAll('[data-gallery-lightbox-items]', galleryLightbox);

        // add to each image current viewport size
        for (var i = 0; i < galleryLightboxImages.length; i++) {
            var image = galleryLightboxImages[i];
            var src = DOM.data(image, 'flickity-lazyload');
            var width = document.innerWidth || 1280;
            var height = document.innerHeight || 720;

            DOM.data(image, 'flickity-lazyload', src + '/s/' + width + '/' + height);
        }

        var lightbox = new Flickity(galleryLightbox, {
            pageDots: false,
            lazyLoad: 2,
            setGallerySize: false,
            prevNextButtons: false
        });

        // pretty lame, but we set the max height on every first child for
        // every lightbox item to avoid images being too high
        var setMaxHeightForLightboxItems = function setMaxHeightForLightboxItems() {
            for (i = 0; i < galleryLightboxItems.length; i++) {
                var item = galleryLightboxItems[i];

                item.firstElementChild.style.maxHeight = (item.offsetHeight - parseInt(
                        window.getComputedStyle(item).getPropertyValue('padding-top'),
                        10
                    ) * 2) + 'px';
            }
        };

        // Init custom page dots
        var dots = DOM.selectAll('.detail__gallery .flickity-page-dots > .dot');
        DOM.on(dots, 'click', function() {
            var index = Array.prototype.indexOf.call(this.parentElement.children, this);
            if (gallery.selectedIndex !== index) {
                this.parentElement.children[gallery.selectedIndex].classList.remove('is-selected');
                gallery.select(index);
                this.classList.add('is-selected');
            }
        });

        DOM.on(window, 'resize load', setMaxHeightForLightboxItems);
        DOM.on('#galleryNext', 'click', gallery.next.bind(gallery));
        DOM.on('#galleryPrevious', 'click', gallery.previous.bind(gallery));
        DOM.on('#lightboxNext', 'click', lightbox.next.bind(lightbox));
        DOM.on('#lightboxPrevious', 'click', lightbox.previous.bind(lightbox));

        // Sync dots to gallery
        gallery.on('cellSelect', function() {
            for (i = 0; i < dots.length; i++) {
                dots[i].classList.remove('is-selected');
            }
            dots[this.selectedIndex].classList.add('is-selected');
        });

        // sync gallery images with lightbox
        gallery.on('cellSelect', syncFn.bind(gallery, lightbox));
        lightbox.on('cellSelect', syncFn.bind(lightbox, gallery));
        lightbox.on('staticClick', function(e) {

            // MI-150: Ugly hack: don't go to next slide when closing the modal
            if (DOM.data(e.target, 'toggle-class')) {
                return false;
            }

            lightbox.next(true);
        });
    };

    return this;
};

module.exports = Gallery;
