/* global require */

'use strict';

var DOM = require('../lib/dom.js');

/**
 * Initializes the (Google) map when viewing the surroundings tab.
 * TODO: Resolve the dependency back to scripts.js (window.App).
 *       We could create a new module for initMap() or move the code here
 */
var SurroundingMap = function SurroundingMap() {

    this.init = function init() {
        var surroundingTab = DOM.select('[data-surroundings]');
        var fn = function(e) {

            window.App.fn.initMap();

            // Init only once
            DOM.off(e.currentTarget, 'click touchstart', fn);
        };

        DOM.on(surroundingTab, 'click touchstart', fn);
    };

    return this;
};

module.exports = SurroundingMap;

