/* global require */

'use strict';

// dependencies
var DOM = require('./dom');

/**
 * Google Maps library.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @param {Object} google (Global) Google object provided by their API.
 * @param {Object} Options to change behaviour of Google Maps library.
 * @return {GMaps}
 */
var GMaps = function(google, options) {

    // shortcuts
    var self = this;

    // public variables
    self.version = '0.1.0';

    // private variables
    var container;
    var defaults = {
        container: null,
        mapOptions: null // goo.gl/wd6PpK
    };
    var map;
    var markers = [];
    var settings = {};
    var tooltip;

    // private methods
    var processResults;

    /**
     * Adds a marker to the map.
     *
     * @param {Object} marker Marker options.
     * @return {GMaps}
     * @see https://developers.google.com/maps/documentation/javascript/reference#MarkerOptions
     */
    self.addMarker = function(markerOptions) {

        // connect marker to this map
        markerOptions.map = map;

        // marker has a name? use it as title
        if (markerOptions.name) {
            markerOptions.title = markerOptions.name;
        }

        // marker has icon, so generate image object
        if (markerOptions.icon) {
            var size = markerOptions.iconSize || 24;
            var image = {
                url: markerOptions.icon,
                scaledSize: new google.maps.Size(size, size)
            };

            markerOptions.icon = image;
        }

        // extract position from geometry object if given
        if (markerOptions.geometry && markerOptions.geometry.location) {
            markerOptions.position = markerOptions.geometry.location;
        }

        // create marker
        var marker = new google.maps.Marker(markerOptions);

        // marker shall be persistent?
        if (markerOptions.persistent === true) {
            return self;
        }

        // if marker is getting clicked, show tooltip with more information
        google.maps.event.addListener(marker, 'click', function() {
            tooltip.setContent(marker.title);
            tooltip.open(map, this);
        });

        markers.push(marker);

        return self;
    };

    /**
     * Adds a list of markers to the map.
     *
     * @param {Array} markers List of markers.
     * @return {GMaps}
     */
    self.addMarkers = function(markers) {
        for (var i = 0; i < markers.length; i++) {
            self.addMarker(markers[i]);
        }

        return self;
    };

    /**
     * Removes all markers from the map.
     *
     * @return {GMaps}
     */
    self.clearMarkers = function() {

        for (var i = 0; i < markers.length; i++) {
            var marker = markers[i];

            marker.setMap(null);
        }

        markers = [];

        return self;
    };

    /**
     * Returns the position displayed at the center of the map.
     *
     * @return {LatLng}
     */
    self.getCenter = function() {
        return map.getCenter();
    };

    /**
     * Sets the center of the map to a given position.
     *
     * @param {LatLng} position
     * @return {GMaps}
     */
    self.setCenter = function(position) {

        // convert string representation
        if (typeof position === 'string') {

            // go for any float "number"
            var pattern = /(?:\d+\.\d+)/g;

            position = {
                lat: pattern.exec(position),
                lng: pattern.exec(position)
            };
        }

        // make sure coordinates are numbers
        position.lat = parseFloat(position.lat);
        position.lng = parseFloat(position.lng);

        map.setCenter(position);
    };

    /**
     * A Nearby Search lets you search for places within a specified area by
     * keyword or type. A Nearby Search must always include a location.
     *
     * @param {Object} options Search options.
     * @param {Function} callback The callback function which gets executed with the results as first parameter.
     * @see https://developers.google.com/maps/documentation/javascript/places#place_search_requests
     */
    self.searchNearby = function(options, callback) {

        // get places service
        var service = new google.maps.places.PlacesService(map);

        // start nearby search
        service.nearbySearch(options, function(results, status, pagination) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                callback.call(self, results);
            }
        });
    };

    /**
     * Initialization logic of Google Maps library.
     */
    (function() {

        options =  options || {};

        // merge new settings into current ones
        for (var key in defaults) {
            if (options.hasOwnProperty(key)) {
                settings[key] = options[key];
            } else {
                settings[key] = defaults[key];
            }
        }

        // create map instance
        map = new google.maps.Map(settings.container, settings.mapOptions);

        // create tooltip for markers
        tooltip = new google.maps.InfoWindow();

    })();
};

module.exports = GMaps;
