/* global DocumentTouch */

'use strict';

// Named depencencies
var fastClick = require("./../../bower_components/fastclick/lib/fastclick.js");
var ClassToggles = require('./lib/classToggles.js');
var SearchAutoComplete = require('./modules/searchAutoComplete.js');
var Search = require('./modules/search');
var Header = require('./modules/header.js');
var SearchWidget = require('./modules/searchWidget');
var SearchCategorySwitcher = require('./modules/searchCategorySwitcher.js');
var SearchResultWidget = require('./modules/searchResultWidget.js');
var EditorWidget = require('./modules/editorWidget.js');
var Subscription = require('./modules/subscription');
var Contact = require('./modules/contact');
var Bookmarks = require('./modules/bookmarks');
var Maps = require('./modules/maps');
var LocateMe = require('./modules/locateMe.js');
var CurtainButtons = require('./modules/curtainButtons.js');
var Tabs = require('./modules/tabs.js');
var SurroundingMap = require('./modules/surroundingMap.js');
var Gallery = require('./modules/gallery.js');
var DetailContentHeigth = require('./modules/detailContentHeight.js');
var Stretchers = require('./modules/stretchers.js');
var FakeSelectValueUpdater = require('./modules/fakeSelectValueUpdater.js');
var ClearLinks = require('./modules/clearLinks.js');
var Wemf = require('./modules/wemf.js');
//var IframeIntegration = require('./modules/iframeIntegration.js');
var NumberTracking = require('./modules/numberTracking.js');
var SliderTeaserWidget = require('./modules/sliderTeaserWidget');
var AlleImmoTATM = require('./lib/AlleImmoTATM');
// var PrivacyModal = require('./modules/privacyModal.js');

// Making console calls safe
window.console = window.console || {};
window.console.log = window.console.log || function() {};
window.console.warn = window.console.warn || function() {};

/**
 * Home.ch App
 *
 * @returns {App}
 * @constructor
 */
var App = function App(debug) {

    debug = debug || false;

    // Shortcut
    var self = this;

    /**
     * Public application functions.
     *
     * @type {Object}
     */
    this.fn = {};

    /**
     * Public application configuration.
     *
     * @type {Object}
     */
    this.c = {
        apiBaseUrl: window.appConfig.apiBaseUrl || 'https://www.homegate.ch',
        language: window.appConfig.language || 'de',
        // TODO: We need to test this and make sure we are doing the right thing.
        // @see https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
        // @see http://www.html5rocks.com/en/mobile/touchandmouse/
        clickEvent: ('ontouchend' in document.documentElement) || ('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch) ? 'touchend' : 'click'
    };

    /**
     * Provide a initialization function for the application.
     *
     * @returns {App}
     */
    this.fn.init = function init() {

        fastClick(document.body);

        // Private modules
        //new IframeIntegration().init();
        new Header().init();
        // new PrivacyModal().init();
        new Bookmarks().init();
        new SearchAutoComplete(self.c.apiBaseUrl, self.c.language).init();
        new SearchCategorySwitcher().init();
        new Search(self.c.apiBaseUrl, self.c.language).init();
        new SearchWidget(self.c.language, document.location.hostname).init();
        new SearchResultWidget().init();
        new EditorWidget().init();
        new Subscription(self.c.language).init();
        new Contact(self.c.language).init();
        new LocateMe().init();
        new CurtainButtons().init();
        new Tabs().init();
        new SurroundingMap().init();
        new Gallery().init();
        new DetailContentHeigth().init();
        new Stretchers().init();
        new FakeSelectValueUpdater().init();
        new ClassToggles().init();
        new ClearLinks().init();
        new NumberTracking(self.c.language).init();
        new SliderTeaserWidget().init();
        new AlleImmoTATM().init();

        var wemf = new Wemf().init();
        // Only track page load if there is a variable in twig that indicates we should do it.
        // Note: If variable is not defined, we just track, as the variable is only defined on
        // pages where we might have to suppress page load trackings like the search view.
        if (window.appConfig && (typeof window.appConfig.shouldTrack === 'undefined' || window.appConfig.shouldTrack)) {
            wemf.track();
        }

        if (debug) {
            console.log('alle-immobilien application initialized.');
        }

        if (!!window.chrome && /google/i.test(navigator.vendor)) {
            document.documentElement.classList.add('chrome');
        }

        return this;
    };

    /**
     * Initializes a (Google) map.
     */
    this.fn.initMap = function initMap() {
        new Maps('google');
    };

    return this;
};

// Poor mans ready function
function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', fn);
    } else {
        document.attachEvent('onreadystatechange', function() {
            if (document.readyState !== 'loading') {
                fn();
            }
        });
    }
}

// Starting the app...
ready(function() {

    // Set to false on production
    window.App = new App(false);

    // Init app
    window.App.fn.init();
});
