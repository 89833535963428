/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Forms = require('../lib/forms');

var Subscription = function Subscription(language) {
    var sendSubscriptionRequest;
    var onSuccess;
    var onError;

    // Defaults
    language = language || 'de';
    var apiBaseUrl = '/' + language + '/sp';

    // Private vars
    var $registerModal = $('#subscriptionModal');
    var $confirmationModal = $('#subscriptionModalConfirmation');
    var $filterForm = $('#filter-form');
    var $subscription = {
        form: $('#subscriptionModalForm'),
        tos: $('input[name="subscriptionTos"]'),
        email: $('input[name="subscriptionEmail"]')
    };

    this.init = function init() {
        $subscription.form.on('submit', function(e) {

            // add submitting state
            Forms.submit(this);

            e.preventDefault();
            sendSubscriptionRequest();
        });
    };

    sendSubscriptionRequest = function() {
        var tosChecked = $subscription.tos.is(':checked');
        var email =  $subscription.email.val();
        var url = window.location.pathname;
        var locationName = $filterForm.find('input[name="locationName"]').val();
        var locationId = $filterForm.find('input[name="locationId"]').val();
        var subscriptionName = $filterForm.find('input[name="subscriptionName"]').val();

        $.ajax(apiBaseUrl + '/register/', {
            data: {
                email: email,
                tos: tosChecked,
                url: url.substring(4, url.length-1), // url without lang part, e.g. rent/f/at-bern
                locationName: locationName,
                locationId: locationId,
                subscriptionName: subscriptionName,
                lang: url.substring(1, 3) // only lang part, e.g. 'en'
            }
        })
        .always(function(data, textStatus, jqXHR) {

            // since request is completed, remove submitting state from form
            Forms.abort($subscription.form[0]);

            if (data.status && data.status === 'success') {
                onSuccess();
            }
            else {
                onError(data);
            }
        });
    };

    onSuccess = function() {
        // Reset possible errors
        for (var field in $subscription) {
            if ($subscription.hasOwnProperty(field)) {
                var $formGroup = $subscription[field].parents('.form-group');
                $formGroup.removeClass('has-error');
                $formGroup.find('.error-text').remove();
            }
        }

        // Google Tag Manager tracking
        if (window.dataLayer) {
            window.dataLayer.push({ 'event': 'search-results-subscription-modal-save' });
        }

        $registerModal.removeClass('open');
        $confirmationModal.addClass('open');
    };

    onError = function(response) {

        if (!response || !response.data) {
            return null;
        }

        // Remove old errors
        $subscription.form.find('.error-text').remove();

        // Generic API errors
        if (response.data.api) {
            var $message = $('<p class="error-text">'+ response.data.api + '<br/>' + response.data.exception + '</p>');
            $message.prependTo($subscription.form);
        }

        // Per field errors
        for (var field in $subscription) {
            if ($subscription.hasOwnProperty(field)) {
                var $formGroup = $subscription[field].parents('.form-group');
                if (response.data.hasOwnProperty(field)) {
                    $formGroup.addClass('has-error');
                    var $helpText = $('<div class="error-text">' + response.data[field] + '</div>');
                    $helpText.appendTo($subscription[field].parent());
                }
                else {
                    $formGroup.removeClass('has-error');
                }
            }
        }
    };

    return this;
};

module.exports = Subscription;
