/* global require */

'use strict';

var DOM = require('../lib/dom.js');

/**
 * Stretchers are UI elements that stretch themself once they have been
 * focused and shrink when they lose it.
 */
var Stretchers = function Stretchers() {

    this.init = function init() {
        var stretchers = DOM.selectAll('[data-stretcher]');
        var stretcher;
        var spacer;

        if (stretchers === null) {
            return;
        }

        var getWidth = function(el) {
            return parseInt(window.getComputedStyle(el).getPropertyValue('min-width'), 10);
        };

        var onBlur = function(e) {
            stretcher.style.width = getWidth(stretcher) + 'px';
        };

        var onFocus = function(e) {
            stretcher.style.width = '100%';
        };

        var onResize = function(e) {
            spacer.style.width = stretcher.style.width = getWidth(stretcher) + 'px';
        };

        for (var i = 0; i < stretchers.length; i++) {
            stretcher = stretchers[i];
            var trigger = DOM.select('[data-stretcher-trigger]', stretcher);

            if (trigger === null) {
                continue;
            }

            // we'll need a space occupying element, because the stretcher won't do it
            spacer = document.createElement('span');

            spacer.classList.add('stretcher-spacer');

            stretcher.parentElement.insertBefore(spacer, stretcher);

            DOM.on(window, 'resize load', onResize);
            DOM.on(trigger, 'focus', onFocus);
            DOM.on(trigger, 'blur', onBlur);
        }
    };

    return this;
};

module.exports = Stretchers;

