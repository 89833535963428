/* global require */

'use strict';

var DOM = require('../lib/dom.js');

var CurtainButtons = function CurtainButtons() {

    this.init = function init() {
        DOM.on('[data-button-curtain]', 'click', function() {
            this.classList.add('show');
        });

        return this;
    };

    return this;
};

module.exports = CurtainButtons;

