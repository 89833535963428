/* global require */

'use strict';

/**
 * DOM helper functions.
 *
 * @author Sebastian Prein <basti@gridonic.ch>
 * @return {Object}
 */
var DOM = (function() {

    var ajax;
    var attr;
    var closest;
    var data;
    var ifClickedOutside;
    var off;
    var on;
    var remove;
    var select;
    var selectAll;
    var trigger;
    var prepareListener;

    /**
     * Check for matches support.
     *
     * @type {Function}
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
     */
    var matches = document.body.matches ||
                  document.body.webkitMatchesSelector ||
                  document.body.mozMatchesSelector ||
                  document.body.msMatchesSelector ||
                  false;


    /**
     * Small XMLHttpRequest wrapper.
     *
     * @param {Object} options XMLHttpRequest options.
     * @return
     */
    ajax = function(options) {
        var request = new XMLHttpRequest();

        if (options === null || typeof options !== 'object') {
            return console.warn('Invalid options given for DOM.ajax().');
        }

        if (options.url === undefined) {
            return console.warn('No URL given for DOM.ajax().');
        }

        if (options.onprogress) {
            request.addEventListener('progress', options.onprogress);
        }

        if (options.onload) {
            request.addEventListener('load', options.onload);
        }

        if (options.onerror) {
            request.addEventListener('error', options.onerror);
        }

        if (options.onabort) {
            request.addEventListener('abort', options.onabort);
        }

        request.open(options.method || 'GET', options.url);
        request.send();
    };
    /**
    * A simple shorthand to manipulate and get attributes of an element.
    *
    * @param {[type]} el Element to use.
    * @param {[type]} attribute Attribute name.
    * @param {[type]} value Attribute value to set. Optional.
    * @return {Mixed}
    */
    attr = function(el, attribute, value) {

        // no value given, just trying to retrieve data
        if (value === undefined) {
            var attr = el.getAttribute(attribute);

            // attribute not set
            if (attr === null) {
                return false;
            }

            // if empty or true as a string given, return true
            if (attr === '' || attr.toLowerCase() === 'true') {
                return true;
            }

            return attr;
        }

        // if value is null, remove the attribute
        if (value === null) {
            return el.removeAttribute(attribute);
        }

        el.setAttribute(attribute, value);
    };

    /**
     * Prepares everything to setup or remove a set of listeners to a set of
     * elements for a set of event types.
     *
     * @param {String} state State of listener: adding or removing.
     * @param {NodeList|Element|String} elements Elements to attach listener to.
     * @param {Array} types The list of event types.
     * @param {Function} fn The event handling function.
     */
    prepareListener = function(state, elements, types, fn) {

        // elements is probably a selector
        if (typeof elements === 'string') {
            elements = selectAll(elements);
        }

        // no elements found for adding event listeners
        if (elements === null) {
            return;
        }

        // if elements is not an array, make it an array
        if (Array.isArray(elements) === false) {
            elements = [ elements ];
        }

        // split up types by whitespace
        types = types.split(' ');

        for (var j = 0; j < types.length; j++) {
            var type = types[j];

            for (var i = 0; i < elements.length; i++) {
                var el = elements[i];

                el[state](type, fn);
            }
        }
    };

    /**
     * Registers a listener function on a or multiple elements for a given set
     * of event types.
     *
     * @param {NodeList|Element|String} elements Elements to register for.
     * @param {String} types Event types separated by whitespace.
     * @param {Function} fn The listener function.
     */
    on = function(elements, types, fn) {
        prepareListener('addEventListener', elements, types, fn);
    };

    /**
     * Unregisters a listener function on a or multiple elements for a given
     * set of event types.
     *
     * @param {NodeList|Element|String} elements Elements to register for.
     * @param {String} types Event types separated by whitespace.
     * @param {Function} fn The listener function.
     */
    off = function(elements, types, fn) {
        prepareListener('removeEventListener', elements, types, fn);
    };

    /**
     * Adds a click listener to the given element, which will unbind itself
     * once a click has been registered outside of the element.
     *
     * @param {Element} el An Element.
     * @param {String} className Class to remove from the object when clicked outside.
     */
    ifClickedOutside = function(el, className) {
        var types = 'click touchstart';
        var fn = function(e) {

            // click is outside of element
            if (closest(e.target, el) === null) {

                // remove classname
                el.classList.remove(className);

                // prevent default action
                e.preventDefault();

                // unbind this event handler
                off(e.currentTarget, types, fn);
            }
        };

        on(document, types, fn);
    };

    /**
     * A simple shorthand to manipulate and get data attributes on an element.
     *
     * @param {[type]} el Element to use.
     * @param {[type]} attribute Attribute name, without data- prefix.
     * @param {[type]} value Attribute value to set. Optional.
     * @return {Mixed}
     */
    data = function(el, attribute, value) {
        return attr(el, 'data-' + attribute, value);
    };

    /**
     * Tries to find the closest ancestor of an element given by a selector or
     * another target element.
     *
     * @param {Element} el Element to start.
     * @param {Element|String} target Target Element or selector.
     * @return {Element|null}
     */
    closest = function(el, target) {

        // found closest ancestor
        if ((typeof target === 'string' && matches.call(el, target)) || el === target) {
            return el;
        }

        // climb up
        var parent = el.parentElement;

        // reached the end
        if (parent === null) {
            return null;
        }

        return closest(parent, target);
    };

    /**
     * Selects an element by a given CSS selector.
     *
     * @param {String} selector CSS selector.
     * @param {Element} el The element from where it should look. Default: body
     * @return {Element|null}
     */
    select = function(selector, el) {

        // by default we select starting from the document
        if (el === undefined) {
            el = document;
        }

        return el.querySelector(selector);
    };

    /**
     * Selects all elements by a given CSS selector.
     *
     * @param {String} selector CSS selector.
     * @param {Element} el The element from where it should look. Default: body
     * @return {Array|null}
     */
    selectAll = function(selector, el) {

        // by default we select starting from the document
        if (el === undefined) {
            el = document;
        }

        var els = el.querySelectorAll(selector);

        if (els.length < 1) {
            return null;
        }

        return Array.prototype.slice.call(els);
    };

    /**
     * Removes an element from the DOM.
     *
     * @param {Element} el The element that should be removed.
     */
    remove = function(el) {
        if (el) {
            el.parentNode.removeChild(el);
        }
    };

    /**
     * Triggers a native event.
     *
     * @param {String} type Event type.
     * @param {Element} el Event target.
     * @param {String} set Event set. Default: HTMLEvents
     */
    trigger = function(type, el, set) {
        var e;

        // no set defined, go for HTMLEvents
        if (set === undefined) {
            set = 'HTMLEvents';
        }

        // if no element is given, trigger by window
        if (el === undefined) {
            el = window;
        }

        // create native event
        e = document.createEvent(set);

        // init event
        e.initEvent(type, true, false);

        // dispatch it
        el.dispatchEvent(e);
    };

    return {
        ajax: ajax,
        attr: attr,
        closest: closest,
        data: data,
        ifClickedOutside: ifClickedOutside,
        off: off,
        on: on,
        remove: remove,
        select: select,
        selectAll: selectAll,
        trigger: trigger
    };
})();

module.exports = DOM;
