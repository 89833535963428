/* global require */

'use strict';

// Dependencies
var $ = require("./../../../bower_components/jquery/dist/jquery.js");
var Events = require('./events.js');

window.jQuery = $;

var SliderTeaserWidget = function SearchResultWidget() {

    var data;

    this.init = function init() {

        if($('#result_list_teaser_slider').length === 0) {
            return;
        }

        data = $.parseJSON($('#slider_interest_rates_data').val());

        $('#result_list_teaser_slider').attr({
            'min': 0,
            'max': data.length - 1
        });

        $(document).on('change', '#result_list_teaser_slider', function(e) {

            var rate = data[$(e.target).val()];

            $('#slider_interest_rate').html(parseFloat(rate.interestRate).toFixed(2) + '%');
            $('#slider_term_in_years').html(rate.termInYears);
        });

        $('#result_list_teaser_slider').val(0).trigger('change');

    };

    return this;
};

module.exports = SliderTeaserWidget;

